import React from 'react';
import axios from 'axios';
import { isMobileOnly } from 'react-device-detect';

import searchAgentBanner from '../../../src/images/entry_pages/search-agent-banner.svg';
import * as helpers from '../../../utils/helpers';

import './index.sass';


const SearchAgentBanner = ({ newSearchAgentPath,
                             page,
                             richSnippetsAvailable,
                             searchAgentHeadline,
                             searchAgentText,
                             siteKey,
                           }) => {

  const marginBottom = { marginBottom: !richSnippetsAvailable ? '64px' : 0 };
  const isDetailView = page === 'detail-view';


  return (
    <div className="search-agent-section container max-width" style={ isDetailView ? marginBottom : {}}>
      <div className="search-agent-section__wrapper">
        <div className="search-agent-section__image-text-wrapper">

          { !isDetailView && !isMobileOnly &&
            <div className="search-agent-section__image-wrapper">
              <img alt="search-banner illustration"
                   className="lazyload"
                   data-expand="100"
                   data-sizes="auto"
                   data-src={searchAgentBanner}
                   height="auto"
                   width={140}
              />
            </div>
          }

          <div className="search-agent-section__text-wrapper">
            <p className="search-agent-section__headline">
              { searchAgentHeadline }
            </p>
            <div>
              <div className="search-agent-section__text" dangerouslySetInnerHTML={{ __html: helpers.parseMd(searchAgentText) }} />
              { siteKey === 'global' &&
                <div className="search-agent-section__text">
                  { I18n.t('lb_showroom.list-view.search_agent.description') }
                </div>
              }
            </div>

          </div>
        </div>

        <div className="search-agent-section__buttons-block">
          <a href={newSearchAgentPath} target="_blank" className="search-agent-section__button sa-button">
            <span>
              { siteKey === 'dk'
                ? I18n.t('search_agents.new.standard_search_agent')
                : I18n.t('search_agents.new.create_a_search_agent')
              }
            </span>
          </a>
        </div>
      </div>
    </div>
  )
};


export default React.memo(SearchAgentBanner);
